import React from "react";
import { Typography, Space } from "antd";
const { Text, Paragraph, Title } = Typography;
export default function Introduction() {
  return (
    <div style={{ textAlign: "center" }}>
      <Title level={4}>HONORING</Title>
      <div
        // direction="horizontal"
        // size={"large"}
        // align="start"
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Paragraph className="introduction-text">
          Hospitality Award <br />
          <Text strong>Susan Santiago</Text>
          <br />
          President, United States and Canada <br />
          Hyatt Hotels and Resorts
        </Paragraph>
        <Paragraph className="introduction-text">
          Humanitarian Award <br />
          <Text strong>Richard R. Buery, Jr.</Text>
          <br />
          Chief Executive Officer <br />
          Robin Hood
        </Paragraph>
      </div>
    </div>
  );
}
