import React from "react";
import { Result, Button, Typography } from "antd";
import { SmileOutlined } from "@ant-design/icons";
const { Text, Paragraph } = Typography;
export default function CompleteGala() {
  return (
    <div>
      <Result
        icon={<SmileOutlined />}
        title="Red Carpet Hospitality Gala has ended: A successful event thanks to you!"
        subTitle={
          <Paragraph>
            <Paragraph>
              Thank you for your interest in HANYC Foundation Red Carpet
              Hospitality Gala. We were so happy to see so many people come out
              and participate. The event was a great success, and we couldn't
              have done it without your support. We appreciate your help in
              spreading the word and encouraging people to attend.
            </Paragraph>
            {/* <Paragraph>
              We look forward to a spectacular evening of networking, fun and
              entertainment at the Mandarin Oriental New York this Monday, May
              2nd!
            </Paragraph> */}
          </Paragraph>
        }
      />
    </div>
  );
}
