import React from "react";
import { Form, Input, Button, Space, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
export default function addGuests() {
  const { Text } = Typography;
  return (
    <>
      <Text>
        If you’re also attending, please enter your name in the first entry.
      </Text>
      <Form.List name="guests">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                style={{ display: "flex", marginBottom: 8 }}
                align="baseline"
              >
                <Form.Item
                  {...restField}
                  name={[name, "first"]}
                  rules={[{ required: true, message: "Missing first name" }]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "last"]}
                  rules={[{ required: true, message: "Missing last name" }]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
                <Form.Item {...restField} name={[name, "company"]}>
                  <Input placeholder="Company" />
                </Form.Item>
                <Form.Item {...restField} name={[name, "email"]}>
                  <Input placeholder="Email" />
                </Form.Item>

                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Guest Name
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
}
