import React, { useState } from "react";
import { Card, Row, Col, Typography, Alert } from "antd";
const { Meta } = Card;

// export const ticketIds = {
//   contributionTicketType: "23",
//   individualTicket: "24",
// };
export default function SelectTicketType(props) {
  const { Title, Text, Paragraph } = Typography;
  const [ticketType, selectTicketType] = useState("8");
  const onSelect = (event) => {
    let tag = event.currentTarget.dataset.service;
    console.log(tag); // should return Tagvalue
    selectTicketType(tag);
    props.onSelectTicket(tag);
  };
  return (
    <div>
      {" "}
      <Title level={4}>EXCLUSIVE SPONSORSHIP OPPORTUNITIES</Title>
      <Row gutter={[5, 5]} style={{ marginBottom: 8 }}>
        <Col xs={{ span: 24, gutter: 2 }} lg={{ span: 11 }}>
          <Card
            data-service="36"
            hoverable
            title="RED CARPET GALA DINNER SPONSOR"
            style={{ margin: 0, padding: 0 }}
            onClick={(e) => onSelect(e)}
            className={ticketType == "36" ? "selectedService" : ""}
          >
            <ul style={{ marginLeft: 0, padding: 0 }}>
              <li>
                Exclusive listing/logo on all Gala materials and website as a
                Dinner Sponsor
              </li>
              <li>
                Inclusion in pre-event media advisory and name included in all
                press placements
              </li>
              <li>Exclusive signage at event</li>
              <li>Verbal recognition from podium</li>
              <li>Complimentary two-year HANYC membership</li>
              <li>Table of 10 at the Gala</li>
            </ul>
            <Meta title="$50,000" />
          </Card>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 13 }}>
          {/* <Alert
            style={{ textAlign: "center", backgroundColor: "#A40C19" }}
            message={
              <Text strong style={{ color: "white" }}>
                SOLD OUT
              </Text>
            }
            type="error"
          /> */}

          <Card
            data-service="35"
            hoverable
            title="RED CARPET GALA COCKTAIL PARTY SPONSOR"
            style={{
              margin: 0,
              padding: 0,
              // backgroundColor: "rgb(245 245 245)",
            }}
            onClick={(e) => onSelect(e)}
            className={ticketType == "35" ? "selectedService" : ""}
          >
            <ul style={{ marginLeft: 0, padding: 0 }}>
              <li>
                Exclusive Underwriter listing/logo on all Gala materials and
                website as a Cocktail Sponsor
              </li>
              <li>Inclusion in pre-event media advisory</li>
              <li>Logo included on signage at event</li>
              <li>Verbal recognition from podium</li>
              <li>Complimentary two-year HANYC membership</li>
              <li>Table of 10 at the Gala</li>
            </ul>
            <Meta title="$25,000" />
          </Card>
        </Col>
      </Row>
      <Title level={4}>TABLES AND TICKETS</Title>
      <Row gutter={[5, 5]} style={{ marginBottom: 8 }}>
        <Col xs={{ span: 24 }} lg={{ span: 9 }}>
          <Card
            data-service="34"
            hoverable
            title="PREFERRED TABLE SPONSOR "
            style={{ margin: 0, padding: 0 }}
            onClick={(e) => onSelect(e)}
            className={ticketType == "34" ? "selectedService" : ""}
          >
            <ul style={{ marginLeft: 0, padding: 0 }}>
              <li>Preferred table for ten (10) at the dinner</li>
              <li>
                Name/logo included in printed and electronic invitation and
                event program and website
              </li>
              <li>Name/logo displayed on sponsorship signage at the event</li>
              <li>Verbal recognition from podium</li>
            </ul>
            <Meta title="$15,000" />
          </Card>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Card
            data-service="33"
            hoverable
            title="PRIME TABLE SPONSOR"
            style={{ margin: 0, padding: 0 }}
            onClick={(e) => onSelect(e)}
            className={ticketType == "33" ? "selectedService" : ""}
          >
            <ul style={{ marginLeft: 0, padding: 0 }}>
              <li>Prime table for ten (10) at the dinner</li>
              <li>
                Name/logo included in printed and electronic invitation and
                event program and website
              </li>
              <li>Name/logo displayed on sponsorship signage at the event</li>
            </ul>
            <Meta title="$10,000" />
          </Card>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 7 }}>
          <Card
            data-service="32"
            hoverable
            title="INDIVIDUAL TICKET "
            style={{ marginLeft: 0, padding: 0, width: "100%" }}
            onClick={(e) => onSelect(e)}
            className={ticketType == "32" ? "selectedService" : ""}
          >
            <Meta title="$1,000" />
          </Card>
        </Col>
      </Row>
      <Row>
        {" "}
        <Col span={24} style={{ marginBottom: 8 }}>
          <Card
            hoverable
            data-service="31"
            title="I/WE CANNOT ATTEND BUT WILL CONTRIBUTE  "
            style={{ margin: 0, padding: 0 }}
            onClick={(e) => onSelect(e)}
            className={ticketType == "31" ? "selectedService" : ""}
          ></Card>
        </Col>
      </Row>
    </div>
  );
}
