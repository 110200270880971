import {
  Form,
  Input,
  InputNumber,
  Button,
  Switch,
  Row,
  Col,
  Divider,
  Select,
  message,
  Spin,
  Radio,
  Steps,
  Typography,
} from "antd";

import PayByCard from "./formComponents/payByCard";
import { SmileOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import SelectTicketType from "./formComponents/selectTicketType";
import React, { useEffect, useState } from "react";
import AddGuests from "../page/formComponents/addGuests";
import Axios from "axios";
import environmentVariable from "../envViables";
import Introduction from "./introduction";
const { Title, Text } = Typography;
const layout = {
  //   labelCol: {
  //     span: 8,
  //   },
  wrapperCol: {
    span: 24,
  },
};

const gutterLayout = { xs: 8, sm: 16, md: 24, lg: 32 };
/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */
const { Option } = Select;
const Checkoutform = () => {
  const ticketIds = {
    contributionTicketType: "31",
    individualTicket: "32",
  };

  const [ticketAmount, setTicketAmount] = useState(0);
  const [ifWithGuests, setIfWithGuests] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState({});
  const [ticketType, setTicketType] = useState("");
  const [contributionAmount, setContributionAmount] = useState(0);
  const [current, setCurrent] = useState(0);
  useEffect(() => {
    getItem();
  }, [ticketType]);

  const getItem = async () => {
    var baseUrl = environmentVariable.apiURL + "/ticket";
    if (ticketType != "") {
      const response = await Axios.post(baseUrl, {
        galaTicketId: ticketType,
      });
      console.log(response);
      if (response.status == 200) {
        var newData = response.data;
        setTicket({ ...newData });
      }
    }
  };
  const onFinish = (values) => {
    let updatedValues = values;
    updatedValues.galaTicketId = ticketType;
    let phone = values.user.phone;
    var formattedPhone;
    if (phone) {
      if (phone.length == 11) {
        var firstThree = phone.slice(1, 4);

        var secondThree = phone.slice(4, 7);

        var lastFour = phone.slice(7, 11);

        formattedPhone =
          "(" + firstThree + ")" + " " + secondThree + "-" + lastFour;
      }
      if (phone.length == 10) {
        var firstThree = phone.slice(0, 3);

        var secondThree = phone.slice(3, 6);

        var lastFour = phone.slice(6, 10);

        formattedPhone =
          "(" + firstThree + ")" + " " + secondThree + "-" + lastFour;
      }
    }
    values.user.phone = formattedPhone;
    console.log(values);
    submitInfo(updatedValues);
  };

  const onChangeTicketAmount = (e) => {
    setTicketAmount(e);
  };
  const changeGuests = (value) => {
    setIfWithGuests(value);
    if (value) {
      form.setFieldsValue({
        guests: [{ first: "", last: "" }],
      });
    }
  };
  const totalPrice = () => {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    var total;
    if (ticketType == "23") {
      return formatter.format(contributionAmount);
    }
    return formatter.format(ticketAmount * (ticket.UnitPrice || 1));
  };
  const submitInfo = async (values) => {
    var baseUrl = environmentVariable.apiURL + "/charge";

    setLoading(true);
    try {
      const response = await Axios.post(baseUrl, values);

      if (paymentMethod == "check") {
        message.success({
          content: "Success! You will receive a confirmation shortly.",
          className: "messageBox",
        });
      } else {
        message.success({
          content:
            "Success! You will receive an invoice for you to pay by card shortly.",
          className: "messageBox",
        });
      }

      form.resetFields();
      setCurrent(0);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        message.error({
          content: "Sorry, there was an error. " + error.response.data.message,
          className: "messageBox",
        });
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };
  const onSelectTicket = async (type) => {
    setTicketType(type);
    setCurrent(1);
    // getItem();
  };
  const onChangeContributionAmount = (amount) => {
    setContributionAmount(amount);
  };
  const stateOptions = () => {
    const data = [
      {
        State: "Alabama",
        Abbrev: "Ala.",
        Code: "AL",
      },
      {
        State: "Alaska",
        Abbrev: "Alaska",
        Code: "AK",
      },
      {
        State: "Arizona",
        Abbrev: "Ariz.",
        Code: "AZ",
      },
      {
        State: "Arkansas",
        Abbrev: "Ark.",
        Code: "AR",
      },
      {
        State: "California",
        Abbrev: "Calif.",
        Code: "CA",
      },
      {
        State: "Colorado",
        Abbrev: "Colo.",
        Code: "CO",
      },
      {
        State: "Connecticut",
        Abbrev: "Conn.",
        Code: "CT",
      },
      {
        State: "Delaware",
        Abbrev: "Del.",
        Code: "DE",
      },
      {
        State: "District of Columbia",
        Abbrev: "D.C.",
        Code: "DC",
      },
      {
        State: "Florida",
        Abbrev: "Fla.",
        Code: "FL",
      },
      {
        State: "Georgia",
        Abbrev: "Ga.",
        Code: "GA",
      },
      {
        State: "Hawaii",
        Abbrev: "Hawaii",
        Code: "HI",
      },
      {
        State: "Idaho",
        Abbrev: "Idaho",
        Code: "ID",
      },
      {
        State: "Illinois",
        Abbrev: "Ill.",
        Code: "IL",
      },
      {
        State: "Indiana",
        Abbrev: "Ind.",
        Code: "IN",
      },
      {
        State: "Iowa",
        Abbrev: "Iowa",
        Code: "IA",
      },
      {
        State: "Kansas",
        Abbrev: "Kans.",
        Code: "KS",
      },
      {
        State: "Kentucky",
        Abbrev: "Ky.",
        Code: "KY",
      },
      {
        State: "Louisiana",
        Abbrev: "La.",
        Code: "LA",
      },
      {
        State: "Maine",
        Abbrev: "Maine",
        Code: "ME",
      },
      {
        State: "Maryland",
        Abbrev: "Md.",
        Code: "MD",
      },
      {
        State: "Massachusetts",
        Abbrev: "Mass.",
        Code: "MA",
      },
      {
        State: "Michigan",
        Abbrev: "Mich.",
        Code: "MI",
      },
      {
        State: "Minnesota",
        Abbrev: "Minn.",
        Code: "MN",
      },
      {
        State: "Mississippi",
        Abbrev: "Miss.",
        Code: "MS",
      },
      {
        State: "Missouri",
        Abbrev: "Mo.",
        Code: "MO",
      },
      {
        State: "Montana",
        Abbrev: "Mont.",
        Code: "MT",
      },
      {
        State: "Nebraska",
        Abbrev: "Nebr.",
        Code: "NE",
      },
      {
        State: "Nevada",
        Abbrev: "Nev.",
        Code: "NV",
      },
      {
        State: "New Hampshire",
        Abbrev: "N.H.",
        Code: "NH",
      },
      {
        State: "New Jersey",
        Abbrev: "N.J.",
        Code: "NJ",
      },
      {
        State: "New Mexico",
        Abbrev: "N.M.",
        Code: "NM",
      },
      {
        State: "New York",
        Abbrev: "N.Y.",
        Code: "NY",
      },
      {
        State: "North Carolina",
        Abbrev: "N.C.",
        Code: "NC",
      },
      {
        State: "North Dakota",
        Abbrev: "N.D.",
        Code: "ND",
      },
      {
        State: "Ohio",
        Abbrev: "Ohio",
        Code: "OH",
      },
      {
        State: "Oklahoma",
        Abbrev: "Okla.",
        Code: "OK",
      },
      {
        State: "Oregon",
        Abbrev: "Ore.",
        Code: "OR",
      },
      {
        State: "Pennsylvania",
        Abbrev: "Pa.",
        Code: "PA",
      },
      {
        State: "Rhode Island",
        Abbrev: "R.I.",
        Code: "RI",
      },
      {
        State: "South Carolina",
        Abbrev: "S.C.",
        Code: "SC",
      },
      {
        State: "South Dakota",
        Abbrev: "S.D.",
        Code: "SD",
      },
      {
        State: "Tennessee",
        Abbrev: "Tenn.",
        Code: "TN",
      },
      {
        State: "Texas",
        Abbrev: "Tex.",
        Code: "TX",
      },
      {
        State: "Utah",
        Abbrev: "Utah",
        Code: "UT",
      },
      {
        State: "Vermont",
        Abbrev: "Vt.",
        Code: "VT",
      },
      {
        State: "Virginia",
        Abbrev: "Va.",
        Code: "VA",
      },
      {
        State: "Washington",
        Abbrev: "Wash.",
        Code: "WA",
      },
      {
        State: "West Virginia",
        Abbrev: "W.Va.",
        Code: "WV",
      },
      {
        State: "Wisconsin",
        Abbrev: "Wis.",
        Code: "WI",
      },
      {
        State: "Wyoming",
        Abbrev: "Wyo.",
        Code: "WY",
      },
    ];
    return data.map((item) => {
      return (
        <Option key={item.Code} value={item.Code}>
          {item.Code}, {item.State}
        </Option>
      );
    });
  };

  const TicketForm = () => {
    return (
      <div className={"mainform"}>
        <Button onClick={() => setCurrent(0)}>
          <ArrowLeftOutlined />
          go back
        </Button>
        <Row gutter={gutterLayout}>
          <Col span={18}>
            <Form
              form={form}
              {...layout}
              name="nest-messages"
              layout="vertical"
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              {ticketType == ticketIds.contributionTicketType ? (
                <Form.Item
                  name={"contributionAmount"}
                  label="How much  would you like to contribute?"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber
                    autoFocus={true}
                    min={1}
                    onChange={onChangeContributionAmount}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name={"ticketAmount"}
                  label={
                    ticketType == ticketIds.individualTicket
                      ? "How many tickets would you like to purchase?"
                      : "How many tables would you like to purchase?"
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber min={1} onChange={onChangeTicketAmount} />
                </Form.Item>
              )}

              <Divider />
              <Row gutter={gutterLayout}>
                <Col span={12}>
                  <Form.Item
                    name={["user", "firstName"]}
                    label="First Name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={["user", "lastName"]}
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={gutterLayout}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={["user", "email"]}
                    label="Email"
                    rules={[
                      {
                        type: "email",
                      },
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item
                    name={["user", "phone"]}
                    label="Phone"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={gutterLayout}>
                <Col span={12}>
                  <Form.Item
                    name={["user", "company"]}
                    label="Company"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={["user", "position"]}
                    label="Position"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name={["user", "address"]}
                label="Address"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Row gutter={gutterLayout}>
                <Col span={12}>
                  <Form.Item
                    name={["user", "city"]}
                    label="City"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={["user", "state"]}
                    label="State"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select style={{ width: 180 }} allowClear>
                      {stateOptions()}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name={["user", "zip"]}
                label="Zip"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {ticketType == ticketIds.contributionTicketType ? (
                <></>
              ) : (
                <Form.Item
                  name={"withGuests"}
                  label="Attendees (If attendees are undecided, please contact Melanie McEvoy at Melanie@mcevoyandassociates.com for follow up.)"
                  valuePropName="checked"
                >
                  <Switch onChange={changeGuests} />
                </Form.Item>
              )}

              {ifWithGuests ? <AddGuests /> : <></>}

              <Divider />
              <Spin spinning={loading} delay={100}>
                <Form.Item
                  name={"paymentMethod"}
                  label="Pay by check or card?"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Radio.Group
                    initialValue="card"
                    onChange={(e) => {
                      setPaymentMethod(e.target.value);
                    }}
                  >
                    <Radio.Button value="check">Check</Radio.Button>
                    <Radio.Button value="card">Card</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {/* {paymentMethod == "card" ? (
         <PayByCard loading={loading} />
       ) : (
         <></>
       )} */}
              </Spin>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loading ? true : false}
                  block
                >
                  Confirm
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={6}>
            <div className="totalAmount">
              <h4>Total: {totalPrice()}</h4>
              <Divider />
              {ticketType == ticketIds.contributionTicketType ? (
                <></>
              ) : (
                <>
                  <p>Tickets: {ticketAmount}</p>{" "}
                  <p>
                    Price: $ {ticket.UnitPrice || ""} /{" "}
                    {ticketIds.individualTicket == ticketType
                      ? "Ticket"
                      : "Table"}
                  </p>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Title level={1} style={{ color: "#A40C19" }}>
          RED CARPET HOSPITALITY GALA
        </Title>
        <Introduction />
        <Divider />
      </div>

      {current == 0 ? (
        <Row gutter={gutterLayout} className="select-ticket-type">
          {" "}
          <SelectTicketType onSelectTicket={onSelectTicket} />{" "}
        </Row>
      ) : (
        <></>
      )}

      {current == 1 ? <TicketForm /> : <></>}
    </>
  );
};

export default Checkoutform;
